<template>
  <section>
    <top-alternative-section
        :image="image"
        :title="$t('suministro.top.title')"
        :content="$t('suministro.top.content')"
    ></top-alternative-section>

    <features-section
      :title="$t('suministro.featuresSection.title')"
      :description="$t('suministro.featuresSection.description')"
      :items="$t('suministro.featuresSection.items')"
    >
    </features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import LightImage from "../../assets/distribucion.png"

export default {
  name: "Suministro",
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  